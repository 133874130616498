<footer class="footer">
  <div class="container">
    <div class="top-section d-flex justify-content-between align-items-center">
      <img class="footer__logo" src="../../../assets/images/logo-white.svg?v={{logoVersionNumber()}}"
        alt="Farmbox Delivery - Logo" />
      <div class="d-flex flex-column align-items-start">
        <div class="h8-regular">Follow Us</div>
        <ul class="social-icons">
          <li><a href="https://www.twitter.com/FarmBoxdelivery/" target="_blank" aria-label="twitter link"><i
                class="fab fa-twitter"></i></a></li>
          <li><a href="https://www.instagram.com/farmbox_delivery/" target="_blank" aria-label="instagram link"><i
                class="fab fa-instagram"></i></a></li>
          <li><a href="https://www.facebook.com/FarmBoxdelivery/" target="_blank" aria-label="facebook link"><i
                class="fab fa-facebook"></i></a></li>
        </ul>
      </div>
    </div>

    <div class="mb-5">
      <h2 class="h3-bold head-font text-center">We're on a mission to support Texas farmers &amp; food makers that have
        a commitment to real food.</h2>
    </div>
    <div class="row">
      <div class="col-lg-9 mb-3 mb-lg-0">
        <div class="row">
          <div class="col-lg-5">
            <h5 class="footer__title--list">Contact</h5>
            <ul class="footer__list">
              <li><a class="icon-list" href="mailto:info@farmboxdl.com"><span
                    class="icon material-symbols-outlined">mail</span>info&#64;farmboxdl.com</a></li>
              <li><a class="icon-list" href="tel:2143907417"><span
                    class="icon material-symbols-outlined">call</span>(214) 390-7417</a></li>
              <li>
                <div class="icon-list"><span class="icon material-symbols-outlined">location_on</span>Serving
                  DFW<br>Waco and Austin, TX</div>
              </li>
            </ul>
          </div>
          <div class="col-lg-3">
            <h5 class="footer__title--list">Info</h5>
            <ul class="footer__list">
              <li><a [routerLink]="['/pages/faq']">FAQ</a></li>
              <li><a [routerLink]="['/terms-and-conditions']">Terms & Conditions</a></li>
              <li><a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSe_u-HvHUYPIGYfPcJcRfQhwAUqiXuIOw3bAfBfV2qyAoSolA/viewform"
                  target="_blank">Cancellation Policy</a></li>

            </ul>
          </div>
          <div class="col-lg-4">
            <h5 class="footer__title--list">Discover</h5>
            <ul class="footer__list">
              <li><a [routerLink]="['/pages/producers']">Farmers & Producers</a></li>
              <li><a [routerLink]="['/pages/why-farmbox']">Why Farmbox</a></li>
              <li><a [routerLink]="['/pages/how-it-works']">How it works</a></li>
              <li><a [routerLink]="['/pages/gift-cards']">Buy a gift card!</a></li>
              <li><a href="https://blog.farmboxdelivery.com/">Blog</a></li>
              <li><a href="https://blog.farmboxdelivery.com/category/recipes/">Recipes</a></li>
            </ul>
          </div>

        </div>
      </div>
      <div class="col-lg-3 position-relative">
        <h5 class="footer__title--list">Account</h5>
        @if (!hasSession()) {
        <ul class="footer__list">
          <li><a [routerLink]="['/login']">Sign In</a></li>
          <li><a [routerLink]="['/signup']">Register</a></li>
        </ul>
        }

        <div class="to-top" (click)="scrollToTop()">
          <i class="icon fa-solid fa-chevron-up"></i>
          <div class="text">To top</div>
        </div>
      </div>
    </div>
    <div class="copy-text">
      <span>Farmbox Delivery © 2025 </span><span>Powered by <a class="link h8-bold" href="https://navego.tech"
          [target]="'_blank'">Navego</a></span>
    </div>
  </div>
</footer>